const Accordian = ({ up }) =>
    up ? (
        <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.999999 6.28137L5.96261 1.31876L10.9252 6.28137"
                stroke="white"
                stroke-width="1.8"
            />
        </svg>
    ) : (
        <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.9252 1.5188L5.96261 6.48141L1 1.5188"
                stroke="white"
                stroke-width="1.8"
            />
        </svg>
    );

export default Accordian;
