import { useEffect, useState } from 'react';
import { trackEngagement, trackImpression } from './tracking.js';
import styles from './FiveYearsToday.module.css';
import Accordian from './Accordian.jsx';

const Teaser = ({ title, url, image_url }) => {
    const imageUrls = image_url?.urls.map(
        ({ url, width }) => `${url} ${width}w`,
    );

    return (
        <article className={styles.teaser}>
            <a
                href={url}
                onClick={() =>
                    trackEngagement('Click', {
                        custom: { title, url },
                    })
                }
            >
                <time>5 år siden i dag</time>
                <div className={styles.teaserContent}>
                    <h3>{title}</h3>
                    {image_url && (
                        <img
                            className="commercial-logo-image"
                            sizes="200px"
                            srcSet={imageUrls.join(', ')}
                            loading="lazy"
                        />
                    )}
                </div>
            </a>
        </article>
    );
};

const FiveYearsToday = ({ node, articles }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        trackImpression(node);
    }, []);

    const expandAction = (e) => {
        e.preventDefault();
        setIsExpanded(!isExpanded);
        trackEngagement('Click', {
            custom: {
                action: isExpanded ? 'Collapse' : 'Expand',
            },
        });
    };

    return (
        <section className={styles.container}>
            <h2 className={styles.headline}>Mest lest for 5 år siden</h2>
            {articles
                .slice(0, isExpanded ? articles.length : 3)
                .map((article) => {
                    const url = `https://www.vg.no/i/${article.content_id}?utm_campaign=five-years-today`;

                    return (
                        <Teaser
                            key={article.id}
                            title={
                                article.google_title || article.content_title
                            }
                            url={url}
                            image_url={article.image_url}
                        />
                    );
                })}
            {isExpanded ? (
                <button className={styles.button} onClick={expandAction}>
                    Vis mindre <Accordian up={true} />
                </button>
            ) : (
                <button className={styles.button} onClick={expandAction}>
                    Vis mer <Accordian up={false} />
                </button>
            )}
        </section>
    );
};

export default FiveYearsToday;
