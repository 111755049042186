import { render } from 'preact';
import FiveYearsToday from './FiveYearsToday.jsx';

const fetchArticles = async () => {
    try {
        const response = await fetch(
            'https://api.vg.no/fastify-experiments/five-years-today',
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching articles:', error);
        return null;
    }
};

const moveLatestNewsBox = () => {
    const elem = document.querySelector('.vgdirekte-container');
    const newContainer = document.querySelector('#direkte-test-placement');
    newContainer.append(elem);
};

// We export a function that receives props, including "props.node"
export default async function initHeiVg(props) {
    console.log('Five years today: Initializing');

    const { node, getUnleashClient } = props;
    if (!node || !(node instanceof HTMLElement)) {
        console.error('No valid node found for five years today');
        return;
    }

    const unleashClient = await getUnleashClient();

    const unleashToggleName = 'five-years-today';
    const isEnabled = unleashClient.isEnabled(unleashToggleName);

    if (isEnabled) {
        const articles = await fetchArticles();

        if (!articles?.articles?.length) {
            console.error('Error fetching articles');
            return;
        }

        console.log('Five years today: Feature toggle enabled, rendering');

        moveLatestNewsBox();
        node.style.display = 'block';
        return render(
            <FiveYearsToday
                articles={articles.articles.slice(0, 5)}
                {...props}
            />,
            node,
        );
    }
}
